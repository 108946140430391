<template>
  <div class="nfRecord">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="recordBg">
      <div class="content">
        <div class="text">
          体验<span> {{ text }} </span>更多功能
        </div>
        <div class="btns">
          <div class="btn">一键办理</div>
          <div class="btn">下载APP</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';

export default {
  name: 'nfRecord',
  data() {
    return {
      breadList: [{ name: '办事指南' }, { name: '南繁备案' }], // 导航
      text: this.$route.query.text || '南繁备案'
    };
  },
  components: {
    nfBreadcrumb
  }
};
</script>

<style lang="less" scoped>
.recordBg {
  background-image: url(~@/assets/images/nfRecord/bk_bszn@3x.png);
  background-size: cover;
  height: 600px;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 220px;
    padding-left: 100px;
    box-sizing: border-box;
    .text {
      letter-spacing: 6px;
      font-size: 35px;
      color: #5e77b4;
      span {
        color: #082265;
      }
    }
    .btns {
      margin-top: 50px;
      .btn {
        float: left;
        width: 180px;
        height: 48px;
        background: #265ae1;
        line-height: 48px;
        text-align: center;
        color: #fff;
        border-radius: 4px;
        margin-left: 25px;
        font-size: 20px;
      }
    }
  }
}
</style>
